<template>
  <div class="solutionIndex page_body_con">
    <div class="nav">
      <div class="page_location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">
            <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>解决方案</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <el-row :gutter="30" class="flex solution_content">
      <el-col :span="6" class="left">
        <div class="page_title_name">
          <h3>解决方案</h3>
          <span>case solution</span>
        </div>
        <div class="desc">
          北京中集智本科技有限公司，是“中集集团”旗下一家提供智能冷链监控设备解决方案的自主创新型科技企业。致力于医药、食品、养殖、农牧业的实际需求，以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。公司拥有资深研发团队和专业管理人才，获得多项科技进步奖项，所有软硬件产品都自主研发，拥有自主知识产权，温控设备相关专利5项，软件著作权12项，自主研发了基于区块链技术应用的云信息平台，帮助客户实现数据的可视可追溯。
        </div>
      </el-col>
      <el-col :span="18" class="flex-space-center">
        <!-- <template v-for="item in items"> -->
        <div
          @mouseover="selectStyle(items[0])"
          :class="[items[0].active ? 'active' : '', curtab > 0 ? 'noimage' : 'image']"
          class="animation-bounceIn"
          @mouseout="outStyle(items[0])"
        >
          <template v-if="curtab != 1">
            <img :src="items[0].default" width="100%" v-if="curtab == 2 || curtab == 3" />
            <img :src="items[0].src" width="100%" v-else />
          </template>

          <img :src="items[0].choosesrc" width="100%" @click="bindSolutionhref(1)" v-else />
        </div>
        <div
          @mouseover="selectStyle(items[1])"
          :class="[items[1].active ? 'active' : '', curtab > 0 ? 'noimage' : 'image']"
          class="animation-bounceIn"
          @mouseout="outStyle(items[1])"
        >
          <template v-if="curtab != 2">
            <img :src="items[1].default" width="100%" v-if="curtab == 1 || curtab == 3" />
            <img :src="items[1].src" width="100%" v-else />
          </template>
          <img :src="items[1].choosesrc" width="100%" v-else @click="bindSolutionhref(2)" />
        </div>
        <div
          @mouseover="selectStyle(items[2])"
          :class="[items[2].active ? 'active' : '', curtab > 0 ? 'noimage' : 'image']"
          class="animation-bounceIn"
          @mouseout="outStyle(items[2])"
        >
          <template v-if="curtab != 3">
            <img :src="items[2].default" width="100%" v-if="curtab == 1 || curtab == 2" />
            <img :src="items[2].src" width="100%" v-else />
          </template>
          <img :src="items[2].choosesrc" width="100%" v-else @click="bindSolutionhref(3)" />
        </div>
        <!-- </template> -->
      </el-col>
    </el-row>
    <div class="custom_box">
      <p>联系我们定制您的业务需求</p>
      <button class="button_yiliao" v-if="curtab == 1 || curtab == 0" @click="bindCustomized">
        定制
      </button>
      <button class="button_shengxian" v-if="curtab == 2" @click="bindCustomized">
        定制
      </button>
      <button class="button_yangzhi" v-if="curtab == 3" @click="bindCustomized">
        定制
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "solutionIndex",
  data() {
    return {
      curtab: 0,
      items: [
        {
          select: "第一行",
          icon: 1,
          src: require("@/assets/images/solution/solution4.png"),
          default: require("@/assets/images/solution/yiliao.png"),
          choosesrc: require("@/assets/images/solution/solution1.png"),
          active: false,
        },
        {
          select: "第二行",
          icon: 2,
          src: require("@/assets/images/solution/solution5.png"),
          default: require("@/assets/images/solution/shipin.png"),
          choosesrc: require("@/assets/images/solution/solution3.png"),
          active: false,
        },
        {
          select: "第三行",
          icon: 3,
          src: require("@/assets/images/solution/solution6.png"),
          default: require("@/assets/images/solution/yangzhi.png"),
          choosesrc: require("@/assets/images/solution/solution2.jpg"),
          active: false,
        },
      ],
      bodyBgImage0: "url(" + require("../../assets/images/solution/Rectangle.png") + ")",
      bodyBgImage1: "url(" + require("../../assets/images/solution/Rectangle1.png") + ")",
      bodyBgImage2: "url(" + require("../../assets/images/solution/Rectangle3.png") + ")",
      bodyBgImage3: "url(" + require("../../assets/images/solution/Rectangle2.png") + ")",
    };
  },
  activated() {
    // 进来的时候调用添加
  },
  beforeDestroy() {
    this.clearBodyBackGround();
  },
  watch: {
    $route: {
      immediate: true, // watch中的监听，第一次创建的时候，不会立即执行，加上这个参数，会立即执行
      handler(to, from) {
        console.log(to, from);
        if (to.path == "") {
          this.setBodyBackGround();
        }
        if (to.path == "/solution/index") {
          this.setBodyBackGround();
        } else {
          this.clearBodyBackGround();
        }
      },
    },
  },
  methods: {
    //点击定制
    bindCustomized() {
      this.$router.push({
        path: "/introduce/contactus",
        query: { type: 1 },
      });
    },
    // 添加body图片
    setBodyBackGround() {
      document.body.style.backgroundSize = "100%";
      document.body.style.backgroundImage = this.bodyBgImage0;
    },
    setBodyBackGround1() {
      console.log("44455");
      document.body.style.backgroundSize = "100%";
      document.body.style.backgroundImage = this.bodyBgImage1;
    },
    setBodyBackGround2() {
      document.body.style.backgroundSize = "100%";
      document.body.style.backgroundImage = this.bodyBgImage2;
    },
    setBodyBackGround3() {
      document.body.style.backgroundSize = "100%";
      document.body.style.backgroundImage = this.bodyBgImage3;
    },
    // 清除背景图
    clearBodyBackGround() {
      document.body.style.backgroundImage = "";
    },
    selectStyle(item) {
      console.log("hover", item);
      var _this = this;
      this.$nextTick(function() {
        this.items.forEach(function(item) {
          _this.$set(item, "active", false);
        });
        this.$set(item, "active", true);
        _this.curtab = item.icon;
        if (item.icon == 1) {
          this.setBodyBackGround1();
        } else if (item.icon == 2) {
          this.setBodyBackGround2();
        } else if (item.icon == 3) {
          this.setBodyBackGround3();
        } else {
          this.setBodyBackGround();
        }
      });
    },
    outStyle(item) {
      console.log("move", item);
      this.$set(item, "active", false);
      this.curtab = 0;
      this.setBodyBackGround();
    },
    bindSolutionhref(e) {
      this.clearBodyBackGround();
      if (e == 1) {
        this.$router.push({
          path: "/solution/coldchain/index",
          query: {
            type: 3,
          },
        });
      } else if (e == 2) {
        this.$router.push({
          path: "/solution/foodfresh/index",
          query: {
            type: 4,
          },
        });
      } else if (e == 3) {
        this.$router.push({
          path: "/solution/breed/index",
          query: {
            type: 5,
          },
        });
      }
    },
  },
};
</script>
<style>
.loginBgc {
  background-repeat: repeat-x;
  background-size: cover;
  height: 100vh;
  overflow-y: hidden;
  background: url("../../assets/images/service/Rectangle.png") 100% 100%;
}
</style>
<style lang="scss" scoped>
.solutionIndex {
  height: 900px;
  // padding:30px 0;
  // background: url("../../assets/images/service/Rectangle.png") 100% 100%;
}
.nav {
  padding-top: 120px;
  padding-bottom: 35px;
}
// .solution_content{
//     padding-bottom: 60px;
// }
.left {
  .title {
    margin-top: 15px;
    h6 {
      font-size: 32px;
      font-family: Source-KeynoteartHans-Regular, Source-KeynoteartHans;
      font-weight: 600;
      color: #000000;
      line-height: 36px;
    }
    span {
      font-size: 40px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 58px;
    }
  }
  .desc {
    text-indent: 28px;
    margin-top: 30px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #575757;
    line-height: 28px;
    letter-spacing: 1px;
  }
}
.custom_box {
  height: 48px;
  display: flex;
  // margin-right: 60px;
  justify-content: flex-end;
  margin: 30px 0px 60px 0;
  //   padding-bottom: 60px;
  p {
    width: 420px;
    height: 60px;
    line-height: 60px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    text-align: center;
    font-weight: 800;
    color: #595959;
  }
  .button_yiliao {
    width: 120px;
    height: 60px;
    background: #006acd;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
  .button_shengxian {
    width: 120px;
    height: 60px;
    background: #ffc700;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
  .button_yangzhi {
    width: 120px;
    height: 60px;
    background: #53b26d;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    font-weight: 800;
    color: #ffffff;
  }
}
.image {
  img {
    display: inline-block;
    width: 286px;
    height: 560px;
    // margin-left: 25px;
    cursor: pointer;
  }
}
.noimage {
  // width: 120px;
  // height: 560px;
  // background: #FFC700;
  // border-radius: 0px 0px 0px 0px;
  // opacity: 1;
  img {
    display: inline-block;
    width: 150px;
    height: 560px;
    // margin-left: 25px;
    cursor: pointer;
  }
}
.active {
  img {
    display: inline-block;
    width: 560px;
    height: 560px;
    // margin-left: 25px;
    cursor: pointer;
    // -webkit-transform: translate(-30px, 0px);
    //  -moz-transform: translate(-30px, 0px);
    // transform: translate(-30px, 0px);
    transition: all 0.5s;
    // transition: all 0.3s ease-out 0s;
  }
}
</style>

<template>
  <div class="banner_box">
    <el-carousel
      :interval="4000"
      @change="changeImg"
      style="width: 100%; margin-top: 1px"
      trigger="click"
      :height="bannerHeight + 'px'"
    >
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <el-image
          :class="className"
          style="width: 100%; height: auto"
          ref="bannerImg"
          :src="item.src"
          fit="cover"
          @load="bannerLoad"
        ></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      className: "",
      bannerHeight:0,
    };
  },
  props: ["bannerList"],
  activated() {
    window.scrollTo(0, 0);
    this.className = "lun-img";
    setTimeout(() => {
      this.className = "lun-img-two";
    }, 300);
  },

  methods: {
    changeImg(e) {
      // console.log(e, "当前下标");
      this.className = "lun-img";
      setTimeout(() => {
        this.className = "lun-img-two";
      }, 300);
    },
    bannerLoad(){
      this.$nextTick(()=>{
        this.bannerHeight = this.$refs.bannerImg[0].$el.clientHeight;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.arrow {
  display: inline-block;
  cursor: pointer;
}
.carousel-map {
  width: 100%;
  height: 580px;
  overflow: hidden;
  .lun-img {
    transform: scale(1.5);
  }
  .lun-img-two {
    transition: all 3s;
    transform: scale(1);
  }
  .el-carousel__item.is-animating {
    transition: all 0.6s;
  }
}
</style>

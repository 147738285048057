<template>
  <div class="honerIndex">
    <div class="top">
      <div class="page_body_con">
        <div class="page_title_name">
          <h3>公司荣誉</h3>
          <span>Company Honor</span>
        </div>
      </div>
    </div>
    <div class="margin-tb-15 page_body_con">
      <div class="slideshow">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, i) in pictures"
              :key="i"
              class="swiper-slide"
              @click="bindDetail(i)"
            >
              <!-- 具体内容 -->

              <img
                :src="
                  require('../../assets/images/introduce/certificate/' +
                    (i + 1) +
                    '.png')
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入swiper插件的js
import Swiper from "@/tools/swiper.min.js";
import "@/tools/swiper.min.css";
export default {
  data() {
    return {
      pictures: [
        require("@/assets/images/introduce/certificate/1.png"),
        require("@/assets/images/introduce/certificate/2.png"),
        require("@/assets/images/introduce/certificate/3.png"),
        require("@/assets/images/introduce/certificate/4.png"),
        require("@/assets/images/introduce/certificate/5.png"),
        require("@/assets/images/introduce/certificate/6.png"),
        require("@/assets/images/introduce/certificate/7.png"),
        require("@/assets/images/introduce/certificate/8.png"),
      ],
    };
  },
  activated() {
    // window.scrollTo(0, 0);
    this.$nextTick(() => {
      this.myswiper();
    });
  },
  methods: {
    //点击定制
    bindCustomized() {
      this.$router.push({
        path: "/introduce/contactus",
        query: { type: 1 },
      });
    },
    bindDetail(e) {
      console.log(e);
    },
    myswiper: function () {
      var swiper = new Swiper(".swiper-container", {
        loop: true,
        // speed: 1000,
        // slidesPerView: "4",
        slidesPerView: 5,
        loopedSlides: 5,
        centeredSlides: true,
        preventClicksPropagation: false, //阻止默认事件
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 1000, //3秒切换一次
          pauseOnMouseEnter: true, //悬停停止切换
        },
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slideshow {
  width: 100%;
  overflow: hidden;
  margin-top: 0.63rem;
}
.top {
  width: 100%;
  height: 160px;
  background: url("../../assets/images/introduce/honer_top.png");
  background-size: 100% 100%;
  margin-bottom: 25px;
}

.slideshowtext {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #fff;
  padding: 15px 10px;
  background: #006acd;
  h6 {
    font-weight: bold;
    color: #ffffff;
    font-size: 24px;
    height: 34px;
    line-height: 34px;
    margin-bottom: 5px;
  }
}
// .swiper-container {
//   width: 350px;
// }
.swiper-wrapper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 18px;

  width: 100%;
  height: 100%;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 500ms;
  cursor: pointer;
  transform: scale(0.8);
}

img {
  width: 260px;
  height: 360px;
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
.swiper-button-next,
.swiper-button-prev {
  margin-top: 180px;
  i {
    font-size: 40px;
    font-weight: bold;
    color: #006acd;
  }
}
.swiper-button-next {
  right: 8%;
}
.swiper-button-prev {
  left: 8%;
}
.swiper-button-next {
  // background: url("../../../assets/images/icon_right.png");
  background: none;
  width: 50px;
  height: 50px;
}
.swiper-button-prev {
  // background: url("../../../assets/images/icon_left.png");
  background: none;
  width: 50px;
  height: 50px;
}

.honerIndex {
  height: auto;
  // background: #f5f5f5;
  // padding:30px 0;
  // background: url("../../assets/images/service/Rectangle.png") 100% 100%;
}
.nav {
  padding-top: 120px;
  padding-bottom: 30px;
}
.desc {
  padding: 5px 0;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #575757;
  line-height: 28px;
  letter-spacing: 1px;
  text-indent: 40px;
}
</style>


<template>
  <div class="page">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {

  activated() {
    window.scrollTo(0, 0);
  },
  
  
}
</script>

<style lang="scss" scoped>
</style>
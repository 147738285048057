<template>
  <div class="page">
    <div class="page_header_img">
      <img :src="require('@/assets/images/header/bg_moment.png')" width="100%" />
      <img :src="require('@/assets/images/header/bg_gray.png')" width="100%" class="header_bottom" />
      <div class="search">
        <div class="search-item">
          <span class="label">
            城市：
            <el-select v-model="value" size="mini" style="width:100px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </span>
        </div>
        <div class="search-item">
          <span class="label">
            时间：
            <el-date-picker size="mini" v-model="value1" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </span>
        </div>
        <div class="search-item">
          <el-button size="mini">搜索</el-button>
        </div>
        <div class="search-item">
          <el-button size="mini">重置</el-button>
        </div>
      </div>
    </div>
    <!--  -->
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      value1: '',
      options: []
    }
  },

  activated() {
    window.scrollTo(0, 0);
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.search {
  position: absolute;
  bottom: 20px;
  left: 0;
}
.search-item {
  display: inline-block;
  margin-left: 40px;
}
</style>
<template>
  <div
    class="solution"
    :class="[CurRouter == '/solution/index' ? 'bg' : 'white_bg']"
  >
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {
      CurRouter: "",
      bodyBgImage0:
        "url(" + require("../assets/images/solution/Rectangle.png") + ")",
    };
  },
  activated() {
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    console.log("我马上被销毁了");
  },
  destroyed() {
    console.log(12);
    this.clearBodyBackGround();
  },
  watch: {
    $route: {
      handler: function (route) {
        console.log(route);
        this.CurRouter = route.path;

      },
      immediate: true,
    },
  },
  methods: {

    // 清除背景图
    clearBodyBackGround() {
      document.body.style.backgroundImage = "";
    },
  },
};
</script>

<style lang="scss">
.bg {
  // background: url("../assets/images/solution/Rectangle.png") 100% 100%;
}
.solution {
  // height: 880px;
  // // padding:30px 0;
  // background: url("../assets/images/service/Rectangle.png") 100% 100%;
  // .title {
  //   height: 32px;
  //   font-size: 23px;
  //   font-family: PingFang SC;
  //   font-weight: 600;
  //   color: #1a1a1a;
  //   opacity: 1;
  // }
  .row {
    width: 100%;
    height: 96px;
    // margin-bottom: 40px;
    .left {
      display: inline-block;
      vertical-align: top;
      width: 247px;
      height: 100%;
      border-right: 0.5px solid #007c00;
      div {
        // width: 158px;
        // height: 68px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 24px;
        color: #333333;
        opacity: 1;
      }
    }
    .right {
      display: inline-block;
      vertical-align: top;
      width: 427px;
      height: 100%;
      padding-left: 37px;
      p {
        // width: 389px;
        // height: 68px;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 300;
        // line-height: 16px;
        color: #333333;
        opacity: 1;
        margin-block-start: 0 !important;
        margin-block-end: 0 !important;
      }
    }
  }
  .image {
    // margin-top: 40px;
    // margin-bottom: 88px;
  }
}
</style>
<template>
  <div>
    <div class="footer">
      <div class="footer_body page_body_con flex">
        <div class="footer_body_container flex-around">
          <div class="footer_column">
            <div class="footer_column_title" @click="$router.push('/introduce')">
              <span class="navline">网站</span>导航 SITE NAVIGATION
            </div>
            <div class="footer_column_box">
              <div class="footer_column_item" @click="$router.push('/')">
                网站首页
              </div>
              <div class="footer_column_item" @click="$router.push('/introduce/index')">
                关于智本
              </div>
              <div class="footer_column_item" @click="$router.push('/product/list')">
                产品中心
              </div>
              <div class="footer_column_item" @click="$router.push('/solution/index')">
                解决方案
              </div>
              <div class="footer_column_item" @click="$router.push('/news/list')">
                新闻中心
              </div>
              <div class="footer_column_item" @click="$router.push('/support')">
                技术支持
              </div>
              <div class="footer_column_item" @click="$router.push('/platform')">
                管理平台
              </div>
            </div>
          </div>
          <div class="footer_column">
            <div class="footer_column_title" @click="$router.push('/introduce/contactus')">
              <span class="navline"> 联系</span>我们 CONTENT US
            </div>
            <div class="footer_column_phone">公司邮箱：zjzl@ccsc58.com</div>
            <div class="footer_column_phone">公司电话：400-618-0990 售后服务转1，购买产品或服务转2 </div>
            <div class="footer_column_phone">
              公司地址：北京市丰台区总部基地16区11号楼5层
            </div>
          </div>

          <div class="footer_column">
            <div class="footer_column_title" @click="$router.push('/introduce/contactus')">
              <span class="navline">关注</span>我们 CONCERN US
            </div>

            <div class="footer_column_code"></div>
            <div class="footer_column_phone">扫一扫，关注我们</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ta-c copybox flex-center">
      © Copyright 2022-2024 北京中集智本科技有限公司 版权所有
      <a href="https://beian.miit.gov.cn" target="_blank">京ICP备16051215号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    pageStyle() {
      if (this.$route.path === "/search") {
        return {
          backgroundImage: `url(${require("@assets/images/bg_app_footer_white.png")})`,
        };
      } else {
        return {
          backgroundImage: `url(${require("@assets/images/bg_app_footer.png")})`,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 258px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #006acd;
  color: #fff;
  // background-image: url("~@assets/images/bg_app_footer.png");
  background-size: 100% 100%;
  .footer_icon_container {
    height: 59px;
    display: relative;
    .footer_icon {
      width: 88px;
      height: 37px;
      // background-image: url("~@assets/images/logo.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .footer_body {
    height: 280px;
    .footer_body_container {
      width: 100%;
      height: 100%;
      .footer_column {
        padding: 0 30px 0 60px;
        width: 30%;
        display: inline-block;
        margin: 45px 0 5px 0px;
        height: 200px;
        // border-left: 1px solid #c9c9c9;
        .footer_column_title {
          span {
            display: inline-block;
            height: 34px;
            line-height: 32px;
            border-bottom: 2px solid #fff;
          }
          cursor: pointer;
          font-weight: 500;
          line-height: 30px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          line-height: 32px;
          margin-bottom: 30px;
        }
        .footer_column_box {
          display: flex;
          flex-wrap: wrap;
          padding: 0 120px 0 0;
          .footer_column_item {
            cursor: pointer;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 300;
            line-height: 42px;
            margin-right: 15px;
          }
        }

        // .footer_column_item:hover {
        //   color: #007c00;
        // }
        .footer_column_phone {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 32px;
        }
        .footer_column_code {
          margin-top: 5px;
          width: 100px;
          height: 100px;
          background-image: url("~@assets/images/qrcode.jpg");
          background-size: 100% 100%;
        }
      }
      .footer_column:nth-child(1) {
        width: 35%;
      }
      .footer_column:nth-child(2) {
        width: 35%;
        border-left: 1px solid #f5f5f5;
        border-right: 1px solid #f5f5f5;
      }
    }
  }
}
.copybox {
  width: 100%;
  height: 46px;
  background: #101010;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  justify-content: center;
  color: #ffffff;
  a {
    font-size: 14px;
    font-family: Source Han Sans CN;
    color: #ffffff;
    font-weight: 400;
    margin-left: 5px;
  }
  a:hover {
    color: #006acd;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px)  {
  .footer_column_item{
    font-size: 12px !important;
  }
  .footer_column_phone{
    font-size: 13px !important;
  }
}
</style>

<template>
  <div class="solution_page">
    <!-- <div class="page_header_img">
      <img :src="require('@/assets/images/header/bg_inventory.png')" width="100%" />
      <img :src="require('@/assets/images/header/bg_white.png')" width="100%" class="header_bottom" />
    </div> -->
    <!-- <div class="page_body_con"> -->
      <!-- <Menu :list="menulist" :activeMenuID="$route.path"></Menu> -->
      <keep-alive>
        <router-view />
      </keep-alive>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenuID: '/serviceGroup',
      menulist: [
        {
          id: "/serviceGroup",
          name: '仓储',
          children: [
            {
              id: "/serviceGroup",
              name: ''
            },
            {
              id: "/serviceGroup/store",
              name: ''
            },
            {
              id: "/serviceGroup/auto",
              name: ''
            },
            {
              id: "/serviceGroup/extra",
              name: ''
            }
          ]

        },


      ]
    }
  },
  activated() {
    window.scrollTo(0, 0);
  },
}
</script>

<style lang="scss" scoped>


</style>
<template>
  <div class="transportDeliery">
    <div class="title">多样化配送</div>
    <div class="row">
      <div class="left">
        <div>丰富的经验和资源</div>
        <div>全面托管您的多样运输需求</div>
      </div>
      <div class="right">
        <p>
          市配+区域<br />整车+零担<br />干线运输<br />智本将根据您的需求，为您安排最优的运输选择。
        </p>
      </div>
    </div>
    <div class="image">
      <img :src="require('@/assets/images/service/8.png')" width="100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "transportDeliery",
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="flex-around home_pop nav_son">
    <div v-for="(item, index) in newsNav">
      <div class="title" @click="bindnavpath(item.name)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  props: {
    newsNav: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    // this.getService();
  },
  methods: {
    getService() {
      this.$http({
        method: "get",
        url: "tree",
        params: {
          limit: 1000,
          type: 3,
          forEdit: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.newsNav = res.data[0].children[0].children;
          // console.log(res.data[0].children)
        }
      });
    },
    bindnavpath(name) {
      console.log(name);
      if (name == "企业新闻") {
        this.$router.push({
          path: "/news",
          query: {
            type: 1,
          },
        });
      } else if (name == "集团新闻") {
        this.$router.push({
          path: "/news",
          query: {
            type: 2,
          },
        });
      } else {
        this.$router.push({
          path: "/news",
          query: {
            type: 1,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
  width: 94px;
  cursor: pointer;
  text-align: center;
}
.home_pop {
  padding: 10px 15px 15px;
}
.cell {
  cursor: pointer;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
  margin-bottom: 4px;
}
.title:hover {
  color: #006acd;
}
@media screen and (min-width: 992px) and (max-width: 1440px)  {
  .title2{
    font-size: 12px !important;
  }
  .title{
    font-size: 12px !important;
  }
 dl dd{
  font-size: 12px !important;
 } 
}
</style>
import Vue from "vue"
import VueRouter from "vue-router"
import main from "../views/main.vue"
import location from "../views/location.vue"
import locationMap from "../views/location/locationMap.vue"
import locationList from "../views/location/locationList.vue"
import locationDetail from "../views/location/locationDetail.vue"
import introduce from "../views/introduce.vue"
import introduceIndex from "../views/introduce/index.vue"
import history from "../views/introduce/history.vue"
import culture from "../views/introduce/culture.vue"
import honor from "../views/introduce/honor.vue"
// import technology from "../views/introduce/technology.vue"
import cooperation from "../views/introduce/cooperation.vue"
import contactus from "../views/introduce/contactus.vue"
import product from "../views/product.vue"
import productList from "../views/product/list.vue"
import productDetail from "../views/product/detail.vue"
import search from "../views/search.vue"
import moment from "../views/moment.vue"
import momentList from "../views/moment/list.vue"
import momentDetail from "../views/moment/detail.vue"
import contact from "../views/contact.vue"
import solution from "../views/solution.vue"
import solutionIndex from "../views/solution/index.vue"
import coldchain from "../views/solution/coldchain/index.vue"
import foodfresh from "../views/solution/foodfresh/index.vue"
import breed from "../views/solution/breed/index.vue"

import coldchainIndex from "../views/solution/coldchain/index.vue"
import solutionInfo from "../views/solution/coldchain/solutionInfo.vue"
import transport from "../views/solution/transport/transport.vue"
import transportDelivery from "../views/solution/transport/transportDelivery.vue"
import transportFleet from "../views/solution/transport/transportFleet.vue"
import transportManagement from "../views/solution/transport/transportManagement.vue"
import service from "../views/solution/service/service.vue"
import serviceBuy from "../views/solution/service/serviceBuy.vue"
import serviceForwarder from "../views/solution/service/serviceForwarder.vue"
import serviceMoney from "../views/solution/service/serviceMoney.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "main",
    // component: main,
    component: () => import('../views/main.vue'),
  },
  {
    path: "/introduce",
    name: "introduce",
    component: introduce,
    redirect: '/introduce/index',
    children: [
      {
        path: "/introduce/index",
        component: introduceIndex,
      },
      {
        path: "/introduce/history",
        name: "history",
        component: history,
      },
      {
        path: "/introduce/culture",
        name: "culture",
        component: culture,
      },
      {
        path: "/introduce/honor",
        name: "honor",
        component: honor,
      },

      {
        path: "/introduce/cooperation",
        name: "cooperation",
        component: cooperation,
      },
      {
        path: "/introduce/contactus",
        name: "contactus",
        component: contactus,
      },
    ],
  },
  {
    path: "/product",
    name: "product",
    component: product,
    redirect: '/product/list',
    children: [
      {
        path: "/product/list",
        name: "productList",
        component: productList,
      },
      {
        path: "/product/productdetail",
        name: "productDetail",
        component: productDetail,
      }
    ],
  },
  {
    path: "/solution",
    component: solution,
    redirect: '/solution/index',
    children: [
      {
        path: "index",
        component: solutionIndex,

      },
      {
        path: "coldchain",
        component: coldchain,
        redirect: '/coldchain/index',
        children: [
          {
            path: "index",
            name: 'coldchainIdex',
            component: () => import('../views/solution/coldchain/list.vue'),
          },
          {
            path: "detail",
            name: 'coldchainDetail',
            component: () => import('../views/solution/coldchain/detail.vue'),
          },
        ],
      },
      {
        path: "foodfresh",
        component:foodfresh,
        redirect: '/foodfresh/index',
        children: [
          {
            path: "index",
            name: 'foodfreshIndex',
            component: () => import('../views/solution/foodfresh/list.vue'),
          },
          {
            path: "detail",
            name: 'foodfreshDetail',
            component: () => import('../views/solution/foodfresh/detail.vue'),
          },
        ],
      },
      {
        path: "breed",
        component:breed,
        redirect: '/breed/index',
        children: [
          {
            path: "index",
            name: 'breedIndex',
            component: () => import('../views/solution/breed/list.vue'),
          },
          {
            path: "detail",
            name: 'breedDetail',
            component: () => import('../views/solution/breed/detail.vue'),
          },
        ],
      },
      {
        path: "service",
        component: service,
        children: [
          {
            path: "",
            component: serviceBuy,
          },
          {
            path: "forwarder",
            name: "serviceForwarder",
            component: serviceForwarder,
          },
          {
            path: "money",
            name: "serviceMoney",
            component: serviceMoney,
          },
        ],
      },
    ],
  },
  {
    path: "/news",
    component: ()=>import('../views/news.vue'),
    redirect:'/news/list',
    children: [
      {
        path: "list",
        name: "newsList",
        component: ()=>import('../views/news/list.vue'),
      },
      {
        path: "detail",
        name: "newsDetail",
        component: ()=>import('../views/news/detail.vue'),
      },
    ],
  },
  {
    path: "/location",
    component: location,
    children: [
      {
        path: "",
        component: locationMap,
      },
      {
        path: "locationList",
        name: "locationList",
        component: locationList,
      },
      {
        path: "locationDetail",
        name: "locationDetail",
        component: locationDetail,
      },
    ],
  },
  {
    path: "/moment",
    component: moment,
    children: [
      {
        path: "",
        component: momentList,
      },
      {
        path: "momentDetail",
        name: "momentDetail",
        component: momentDetail,
      },
    ],
  },
  {
    path: "/support",
    component: ()=>import('../views/support.vue'),
    children: [
      {
        path: "",
        component: ()=>import('../views/support/list.vue'),
      }
    ],
  },
  {
    path: "/platform",
    component: ()=>import('../views/platform.vue'),
    redirect:'/platform/index',
    children: [
      {
        path: "index",
        component: ()=>import('../views/platform/index.vue'),
      }
    ],
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/search",
    name: "search",
    component: search,
  },
  {
    path: "*",
    redirect: "/",
  },
  // {
  //   path: "/service",
  //   name: "service",
  //   component: () => import(/* webpackChunkName: "about" */ "../views/service.vue"),
  // },
]

const router = new VueRouter({
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router

<template>
  <div class="flex-align-center">

    </el-select>
    <el-select v-model="options.id" placeholder="请选择" @change="bindChange" v-if="options.children.length==0">
      <el-option :label="options.name" :value="options.id" ></el-option>
    </el-select>

    <el-select v-model="options.id"  value-key="name" @change="bindChange" placeholder="请选择" v-else>
      <el-option :label="options.name" :value="options.id"></el-option>
      <el-option-group
        v-for="group in options.children"
        :key="group.name"
        :label="group.name"
      >
        <el-option
          v-for="item in group.children"
          :key="item.name"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ["options"],

  data() {
    return {
      value: "",
    };
  },
  created(){
    
    // this.value = this.options[0].value;
  },
  methods: {
    bindChange(e) {
      console.log(e)
      this.$emit('bindchange',e)
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select{
  height: 42px;
  background: #F4F4F4;
  width: 160px;
  margin-left: 15px;
}
</style>
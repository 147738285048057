<template>
  <div class="arrow">
    更多
    <i class="el-icon-arrow-right"></i>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.arrow {
  display: inline-block;
  cursor: pointer;
}
</style>
<template>
  <div class="page_body_con">
    <div class="page_header_img">
      <img :src="require('@/assets/images/location/detail_1.png')" width="100%" />
      <!-- <img :src="require('@/assets/images/header/bg_white.png')" width="100%" class="header_bottom" /> -->
    </div>
    <div class="locationDetail">
      <div class="title">上海浦东和泽园库</div>
      <p
        class="desc"
      >位于浦东新区龙东大道创运路，毗邻上海绕城高速、外环高速，距离市中心14公里，交通快捷，二期仓库针对城配与电商业务配套设计分拣和传送系统，提供更高效、精准的服务。</p>
      <div class="row">
        <div class="label">仓库参数</div>
        <div class="value">仓库面积：一期：7900 m² 二期：9200 m² 温区选择：冷藏、冷冻、恒温和常温</div>
      </div>
      <div class="row">
        <div class="label">可提供服务</div>
        <div class="value">仓储及库内增值服务、市内及区域配送</div>
      </div>
      <div class="row">
        <div class="label">联系方式</div>
        <div class="value">
          <div>姓名：Ke Liu</div>
          <div>电话：021-5330 8266，181 9247 2667</div>
          <div>邮箱：kliu@gfscold.com</div>
          <div>地址：上海市浦东新区合庆镇创运路376号</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'locationDetail',
  activated() {
    window.scrollTo(0, 0);
  },
}
</script>

<style lang="scss" scoped>
.locationDetail {
  margin: 44px auto;
  padding-bottom: 88px;
  .title {
    // width: 180px;
    height: 32px;
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 600;
    // line-height: 27px;
    color: #1a1a1a;
    opacity: 1;
    text-align: center;
    margin-bottom: 23px;
  }
  .desc {
    width: 675px;
    // height: 170px;
    font-size: 10px;
    font-family: PingFangSC-Light;
    font-weight: 300;
    // line-height: 17px;
    color: #333333;
    opacity: 1;
  }
  .row {
    .label {
      display: inline-block;
      vertical-align: top;
      width: 80px;
      color: #333333;
      font-size: 11px;
      font-family: PingFangSC-Medium;
      font-weight: 600;
    }
    .value {
      display: inline-block;
      vertical-align: top;
    }
  }
}
</style>
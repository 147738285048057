<template>
  <div class="page">
    <div class="page_header_img">
      <img :src="require('@/assets/images/header/bg_locationList.png')" width="100%" />
      <img
        :src="require('@/assets/images/header/bg_white_small.png')"
        width="100%"
        class="header_bottom"
      />
    </div>
    <div class="location_row">
      <div class="location_item">
        <div class="image image_1"></div>
        <div class="title">上海浦东和泽园库</div>
        <div class="name">Ke Liu</div>
        <div class="phone">181 9247 2667</div>
        <div class="email">kliu@gfscold.com</div>
        <div class="more" @click="$router.push('/location/locationDetail')">
          更多
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
      <div class="location_item">
        <div class="image image_2"></div>
        <div class="title">天津西青辛口库</div>
        <div class="name">David Chen</div>
        <div class="phone">186 0221 5066</div>
        <div class="email">wchen1@gfscold.com</div>
        <div class="more" @click="$router.push('/location/locationDetail')">
          更多
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
      <div class="location_item">
        <div class="image image_3"></div>
        <div class="title">成都青白江库</div>
        <div class="name">Mavis Ma</div>
        <div class="phone">181 2123 8096</div>
        <div class="email">rhma@gfscold.com</div>
        <div class="more" @click="$router.push('/location/locationDetail')">
          更多
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
    </div>
    <div class="location_row">
      <div class="location_item">
        <div class="image image_4"></div>
        <div class="title">大连智本獐子岛库</div>
        <div class="name">Tracy Cui</div>
        <div class="phone">158 9817 3020</div>
        <div class="email">ytcui1@gfscold.com</div>
        <div class="more" @click="$router.push('/location/locationDetail')">
          更多
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
      <div class="location_item">
        <div class="image image_5"></div>
        <div class="title">上海合庆自动化电商库</div>
        <div class="name">Evan Lian</div>
        <div class="phone">150 0013 2960</div>
        <div class="email">lblian@gfscold.com</div>
        <div class="more" @click="$router.push('/location/locationDetail')">
          更多
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
      <div class="location_item">
        <div class="image image_6"></div>
        <div class="title">
          <span>广州增城库</span>
        </div>
        <div class="name">Huilin Liu</div>
        <div class="phone">151 2001 4995</div>
        <div class="email">hlliu@gfscold.com</div>
        <div class="more" @click="$router.push('/location/locationDetail')">
          更多
          <span class="el-icon-arrow-right"></span>
        </div>
      </div>
    </div>
    <div class="pagination ta-c">
      <el-pagination
        layout="prev, pager, next"
        :total="10"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "locationList",
  activated() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
.location_row {
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  .location_item {
    width: 216px;
    margin-right: 15px;
    // width: 68px;
    // height: 39px;
    font-size: 8px;
    font-family: PingFang SC;
    font-weight: 300;
    // line-height: 14px;
    color: #333333;
    opacity: 1;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    .image {
      width: 216px;
      height: 216px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .image_1 {
      background-image: url("~@assets/images/location/1.png");
    }
    .image_2 {
      background-image: url("~@assets/images/location/2.png");
    }
    .image_3 {
      background-image: url("~@assets/images/location/3.png");
    }
    .image_4 {
      background-image: url("~@assets/images/location/4.png");
    }
    .image_5 {
      background-image: url("~@assets/images/location/5.png");
    }
    .image_6 {
      background-image: url("~@assets/images/location/6.png");
    }
    .title {
      // width: 113px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #1a1a1a;
      opacity: 1;
      margin-top: 16px;
      margin-bottom: 19px;
      span {
        border-bottom: 1px soild #000000 !important;
      }
    }
    .more {
      // width: 23px;
      height: 16px;
      font-size: 11px;
      font-family: PingFang SC;
      font-weight: 300;
      // line-height: 19px;
      color: #4d4d4d;
      opacity: 1;
      margin: 35px 0;
      cursor: pointer;
    }
  }
}
.pagination {
  margin-bottom: 55px;
}
</style>

<template>
  <el-dialog :visible.sync="dialogVisible" width="680px" class="popview">

  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
     
      desc: '',
    }
  },

  methods: {
    show() {
      this.dialogVisible = true;
    }
  },

}
</script>

<style lang="scss">
.popview {
  .el-dialog {
    background-color: RGBA(234, 238, 242, 1);
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
<template>
  <div class="page">
    <div class="page_body_con moment-row">
      <div class="title">南京智本助力7-ELEVEn便利店布局江苏</div>
      <p class="info">
        2021跨年夜，南京智本写新篇。12月31日，智本南京公司7-ELEVEn便利店仓配一体项目正式上线！
        金鹰集团是全球知名连锁便利店7-ELEVEn集团在江苏省的战略合作伙伴。智本与金鹰集团合作，南京江宁库作为7-ELEVEn便利店的江苏省共配物流中心，未来将支撑省内200+家门店的多温层仓储及配送需求，助力7-ELEVEn走进每个人的生活，实现7-ELEVEn“
        近且便利” 的品牌理念。
        随着与金鹰集团的深度合作及智本自身不断扩大的客户群，南京智本未来6个月内将基本建成集常、低温仓储、日夜配送半径达300公里的重要物流据点。
        未来智本将继续用心精进、因地制宜，为客户提供冷链物流一站式定制化解决方案。
      </p>
      <img :src="require('@/assets/images/header/bg_moment.png')" width="100%" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      value1: "",
      options: [],
    };
  },
  activated() {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.moment-row {
  text-align: center;
  padding-top: 44px;
  padding-bottom: 72px;
  // overflow: hidden;
  // float: right;
  .title {
    // width: 268px;
    height: 32px;
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    opacity: 1;
    margin-bottom: 17px;
  }
  .info {
    width: 675px;
    height: 110px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 17px;
    color: #333333;
    opacity: 1;
    margin-bottom: 7px;
    text-align: left;
  }
}
</style>

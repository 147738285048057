<template>
  <div class="page1">
    <div class="page_body_con">
      <div class="margin-tb-30 flex-space-center nav_bar">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/product' }">产品中心</el-breadcrumb-item>
            <el-breadcrumb-item>产品详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>

    <div class="page_body_con" id="tabbarBox">
      <div class="flex margin-tb-15">
        <div class="white_bg margin-rt-15 moment-row">
          <div class="title ta-l">{{ productInfo.title }}</div>
          <div class="info" v-html="productInfo.content"></div>
          <div class="share_box">
            <p class="flex-cener-center">分享至：</p>
            <share :config="config"></share>
          </div>
          <div class="ta-c flex-cener-center margin-tp-60">
            <p class="flex-space-center margin-rt-60" v-if="productInfo.prev">
              <span class="margin-rt-15">{{ productInfo.prev.title }}</span>
              <el-button icon="el-icon-arrow-left" @click="bindPrevNext(productInfo.prev.id)"
                >上一篇</el-button
              >
            </p>
            <p class="flex-space-center" v-if="productInfo.next">
              <span class="margin-rt-15">{{ productInfo.next.title }}</span>
              <el-button @click="bindPrevNext(productInfo.next.id)"
                >下一篇<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </p>
          </div>
        </div>
        <!-- <div :class="{ nullbox: tabbarBoxFixed }"></div> -->
        <!-- <div class="white_bg moment-rt" :class="{ isFixed: tabbarBoxFixed }"> -->
        <div class="white_bg moment-rt">
          <div class="page_title_name">
            <h3>产品中心</h3>
            <span>PRODUCT CENTER</span>
          </div>
          <div class="hort">
            <span class="title">热门产品</span>
            <div class="hort_list">
              <div class="gray_bg" v-for="item in hortList" :key="item.id">
                <div class="hort_item" @click="bindPrevNext(item.id)">
                  <h6>{{ item.title }}</h6>
                  <p class="hort_desc line3">{{ item.desc }}</p>
                  <p>{{ item.create_time }} 中集智本</p>
                </div>
              </div>
              <!-- <div class="gray_bg">
                <h6>这里是标题...</h6>
                <p>这是产品详情的第一句话，这是 产品详情的第一句...</p>
                <p>2022/08/09 中集智本</p>
              </div> -->
            </div>
          </div>
          <div class="flex-center">
            <img :src="require('@/assets/images/main/news.png')" class="news_image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      value1: "",
      options: [],
      hortList: [],
      productInfo: {
        prev: {
          title: "",
        },
        next: {
          title: "",
        },
      },
      config: {
        url: "", // 网址，默认使用 window.location.href
        source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        description: "", // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        image: "", // 图片, 默认取网页中第一个img标签
        sites: ["qzone", "qq", "weibo", "wechat"], // 启用的站点
        wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper: "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
      tabbarBoxFixed: false,
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        if (route.path == "/product/productdetail") {
          this.getProductDetail(route.query.id);
          this.id = route.query.id;
          this.config.url = window.location.href;
          console.log(window.location.href);
        }
      },
      immediate: true,
    },
  },

  activated() {
    window.scrollTo(0, 0);
    // this.id = this.$route.query.id;
    // this.getProductDetail(this.$route.query.id);
  },
  destroyed() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    // window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    bindPrevNext(id) {
      this.$router.push({
        path: "/product/productdetail",
        query: {
          id: id,
        },
      });
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //  console.log(document.querySelector("#tabbarBox"))
      if (document.querySelector("#tabbarBox") != null) {
        var offsetTop = document.querySelector("#tabbarBox").offsetTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
        console.log(scrollTop, offsetTop);
        if (scrollTop > offsetTop) {
          this.tabbarBoxFixed = true;
        } else {
          this.tabbarBoxFixed = false;
        }
      }
    },
    getProductList(type_id) {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: 2,
          page: 1,
          type: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.hortList = res.data.data;
        }
      });
    },
    getProductDetail(id) {
      this.$http({
        method: "get",
        url: "website_content_detail",
        params: {
          id,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.productInfo = res.data;
          this.getProductList(res.data.type_id);

          // console.log(res.data[0].children)
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_bar {
  margin-left: 8%;
}
.el-row {
  display: flex;
  position: relative;
  height: auto;
}
#tabbarBox {
  background: #f4f4f4;
}
.moment-row {
  width: 68%;
  margin-left: 8%;
  text-align: center;
  // padding-top: 30px;
  // padding-bottom: 72px;
  padding: 30px 30px 60px;
  background: #fff;

  // overflow: hidden;
  // float: right;
  .title {
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    height: 42px;
    line-height: 36px;
    margin-bottom: 30px;
  }
  .info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: left;
  }
}

.nullbox {
  width: 16%;
  height: 400px;
  visibility: hidden;
}
.moment-rt {
  width: 16.66%;
  padding: 30px 15px 30px 15px;
  height: 800px;

  .page_title {
    h6 {
      // margin: 30px 0 0px 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-style: italic;
      color: #000000;
    }
    span {
      // margin-left: 30px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #000000;
      line-height: 60px;
      height: 60px;
      display: inline-block;
    }
  }
  .hort {
    margin-top: 30px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      line-height: 46px;
      height: 46px;
      margin-bottom: 15px;
    }
    .hort_list {
      display: flex;
      flex-direction: column;
      h6 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        line-height: 26px;
        // height: 32px;
      }
      .hort_desc {
        margin: 5px 0 10px;
      }
      .gray_bg {
        padding: 15px;
        margin-bottom: 15px;
        cursor: pointer;
      }
    }
  }
  .news_image {
    width: 200px;
    height: auto;
  }
}
.isFixed {
  // 动画的名称(必须要有)
  animation-name: move;
  // 动画的运动曲线(linear是匀速stept()是分步)
  animation-timing-function: linear;

  animation-duration: 3s;

  animation-iteration-count: infinite;

  animation-delay: 1s;

  animation-direction: alternate;

  position: fixed;
  top: 60px;
  right: 13%;
  z-index: 900;
  width: calc(16% - 80px);
  margin-top: 15px !important;
}
</style>

<template>
  <div class="flex-around home_pop">
    <div class="title_box" v-for="(item, index) in productNav">
      <div class="title" @click="bindProductType(item.id)">
        {{ item.name }} <i class="el-icon-arrow-right"></i>
      </div>
      <div class="cell_box">
        <div v-for="(sitem, sindex) in item.children" class="cell_boxs">
          <div class="title2" @click="bindProductType(sitem.id)">
            {{ sitem.name }} <i class="el-icon-arrow-right" v-show="sitem.children.length>0"></i>
          </div>
          <dl class="title-cell flex">
            <template v-for="(ssitem, ssindex) in sitem.children">
              <dd @click="bindProductType(ssitem.id)">{{ ssitem.name }}</dd>
            </template>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    productNav: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    bindProductType(e) {
      console.log(e);
      this.$router.push({
        path: "/product/list",
        query: {
          type: e,
        },
      });
    },
    bindProductInfo(e) {
      console.log(e);
      this.$router.push({
        path: "/product/productdetail",
        query: {
          id: e,
        },
      });
    },
    getProduct() {
      this.$http({
        method: "get",
        url: "tree",
        params: {
          limit: 1000,
          type: 1,
          forEdit: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.productNav = res.data[0].children[0].children;
          // console.log(res.data[0].children)
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-around{

}
.title {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
  width: 140px;
  cursor: pointer;
}
.title:hover {
  color: #006acd;
}
.cell_box {
  display: flex;
  flex-direction: column;
}
.title2 {
  width: 140px;
  font-size: 15px;
  cursor: pointer;
}
.title2:hover {
  color: #006acd;
}
dl {
  width: 440px;
  display: flex;
  flex-wrap: wrap;

  dd {
    width: 140px;
    font-size: 14px;
    margin: 0px 0px 5px;
  }
  dd:hover {
    color: #006acd;
    font-weight: 500;
  }
}

.home_pop {
  width: 40vw;
  max-height: 98vh;
  overflow-y: auto;;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  display: block;
  .el-breadcrumb {
    margin-bottom: 15px;
  }
}
.title_box {
  display: flex;
  align-items: baseline;
}
.cell_boxs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  cursor: pointer;
}
.cell {
  cursor: pointer;
  font-size: 14px;
  color: #666;
  margin-right: 15px;
  margin-bottom: 10px;
}
.cell:hover {
  color: #006acd;
}
@media screen and (min-width: 1440px) and (max-width: 1900px)  {
  .home_pop{
    width: 55vw !important;
    max-height: 98vh !important;
    overflow-y:auto ;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px)  {
  .home_pop{
    width: 70vw !important;
    height: 98vh !important;
    overflow-y:auto ;
  }
  .title2{
    font-size: 12px !important;
  }
  .title{
    width: 180px !important;
    font-size: 14px !important;
  }
 dl dd{
  font-size: 12px !important;
 } 
}
// 全局滚动条样式
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(170, 167, 167, 0.2);
  background-color: #eee;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(170, 167, 167, 0.2);
  border-radius: 5px;
  background-color: #eee;
}
</style>
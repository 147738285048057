<template>
  <div class="page_body_con">
    <el-row class="cooperative_box">
      <el-col :span="7" class="box_lf">
        <!-- <img :src="require('@/assets/images/introduce/cooperative_left.png')" /> -->
        <div class="page_title_name">
          <h3>合作伙伴</h3>
          <span>COMPANY PROFILE</span>
        </div>
        <div class="desc">
          感谢！合作伙伴与我们一同成长、携手前进！
          未来，希望我们能为社会创造更多的价值 让冷暖智知，令世界美好
        </div>
        <div class="flex page_introduce_box">
          <p class="page_introduce_data_box">
            <span class="data_info">10000+</span
            ><span class="data_title">合作客户</span>
          </p>
          <p class="page_introduce_data_box">
            <span class="data_info">300+</span
            ><span class="data_title">合作项目</span>
          </p>
        </div>
      </el-col>
      <el-col :span="16" :offset="1" class="box_rt">
        <el-carousel :interval="3000" arrow="never">
          <el-carousel-item v-for="item in pictures" :key="item.type">
            <!-- <h3>{{ item }}</h3> -->
            <div class="itemBox">
              <p
                class="item"
                v-for="(sitem, sindex) in item.imgs"
                :key="sitem + sindex"
              >
                <img :src="sitem" width="100%" />
              </p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictures: [
        {
          type: "lenglian",
          imgs: [
            require("@/assets/images/introduce/cooperation/1.png"),
            require("@/assets/images/introduce/cooperation/2.png"),
            require("@/assets/images/introduce/cooperation/3.png"),
            require("@/assets/images/introduce/cooperation/4.png"),
            require("@/assets/images/introduce/cooperation/5.png"),
            require("@/assets/images/introduce/cooperation/6.png"),
            require("@/assets/images/introduce/cooperation/7.png"),
            require("@/assets/images/introduce/cooperation/8.png"),
            require("@/assets/images/introduce/cooperation/9.png"),
          ],
        },
        {
          type: "shengxian",
          imgs: [
            require("@/assets/images/introduce/cooperation/10.png"),
            require("@/assets/images/introduce/cooperation/11.png"),
            require("@/assets/images/introduce/cooperation/12.png"),
            require("@/assets/images/introduce/cooperation/13.png"),
            require("@/assets/images/introduce/cooperation/14.png"),
            require("@/assets/images/introduce/cooperation/15.png"),
            require("@/assets/images/introduce/cooperation/16.png"),
            require("@/assets/images/introduce/cooperation/17.png"),
            require("@/assets/images/introduce/cooperation/18.png"),
          ],
        },
        {
          type: "nongye",
          imgs: [
            require("@/assets/images/introduce/cooperation/19.png"),
            require("@/assets/images/introduce/cooperation/20.png"),
            require("@/assets/images/introduce/cooperation/21.png"),
            require("@/assets/images/introduce/cooperation/22.png"),
            require("@/assets/images/introduce/cooperation/23.png"),
            require("@/assets/images/introduce/cooperation/24.png"),
            require("@/assets/images/introduce/cooperation/25.png"),
            require("@/assets/images/introduce/cooperation/26.png"),
            require("@/assets/images/introduce/cooperation/27.png"),
          ],
        },
      ],
    };
  },
};
</script>

<style>
.cooperative_box .el-carousel__container {
  position: relative;
  height: 340px;
}
.cooperative_box {
  /* margin-top: 30px; */
  display: flex;
  height: 380px;
  align-items: flex-start;
}
.box_rt {
  padding: 0 0 0 30px;
  flex: 1;
  margin-left: 60px;
  margin-top: 15px;
}
.el-carousel__item h3 {
  /* color: #475669; */
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}
</style>
<style lang="scss" scoped>
.box_lf {
  height: 380px;
  .desc {
    margin-top: 30px;
    width: 284px;
    height: 105px;
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 35px;
  }
  .page_introduce_box {
    .page_introduce_data_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      // margin-left: 5px;
      margin-top: 30px;
      .data_info {
        width: auto;
        height: 59px;
        font-size: 48px;
        font-family: zcoolwenyiti-Regular, zcoolwenyiti;
        font-weight: 400;
        color: #000000;
        line-height: 59px;
      }
      .data_title {
        width: 68px;
        height: 20px;
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
    }
    .page_introduce_data_box:last-child {
      margin-left: 15px;
    }
  }
}
.itemBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item {
    width: 33%;
    height: 100px;
    margin-bottom: 15px;
    img {
      display: inline-block;
      height: 100px;
      width: auto;
    }
  }
}
</style>
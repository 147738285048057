import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/index.js"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import BaiduMap from 'vue-baidu-map'
import "./assets/css/main.scss";
import http from './http'
Vue.prototype.$http = http
Vue.use(BaiduMap, {
  ak: 'XP1alssWsEscC3NfYAhj6YfqKvgQgUXF'
})
Vue.config.productionTip = false
Vue.use(ElementUI)

//
import MoreButt from "@/components/MoreButt.vue"
Vue.component("MoreButt", MoreButt)

import Menu from "@/components/Menu.vue"
Vue.component("Menu", Menu)

import qs from 'qs'
Vue.prototype.$qs = qs

import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';

Vue.use(Share)
var mixin = {
  // activated() {
  //   window.scrollTo(0, 0)
  // },
}

new Vue({
  mixins: [mixin],
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")

<template>
    <div class="transportManagement">
        <div class="title">TMS</div>
        <div class="row">
            <div class="left">
                <div>灵活调度、信息监控、线上反馈</div>
                <div>在途货物看得见</div>
            </div>
            <div class="right">
                <p>自主研发的TMS系统及调度算法，规划最优安排，获取最佳路径。<br/>温度、位置、执行进度直观可见，监控预警极速响应.<br/>回单、结费线上化，交易实现在指尖。</p>
            </div>
        </div>
        <div class="image">
            <img :src="require('@/assets/images/service/7.png')" width="100%"/>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: "transportManagement"
    }
</script>

<style lang="scss" scoped>

</style>
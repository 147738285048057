<template>
  <div class="page">
    <div class="page_header_img">
      <img :src="require('@/assets/images/header/bg_contact.png')" width="100%" />
      <img
        :src="require('@/assets/images/header/bg_white_small.png')"
        width="100%"
        class="header_bottom"
      />
    </div>
    <div class="contact-container">
      <div class="contact-first-row">
        <div class="title">联系我们</div>
        <div class="value">
          <span>400-618-0990</span>
          <span>/</span>
          <span>
            <span class="email">GFS info@gfscold.com</span>
          </span>
        </div>
        <div class="value">上海市浦东新区张扬路2389弄3号普洛斯大厦</div>
      </div>
      <div class="contact-row">
        <div class="contact-item">
          <div class="title">运输</div>
          <div class="value">
            Chen Jason
            <span>/</span> 189-3996-7782
          </div>
          <div class="email">ajchen@gfscold.com</div>
          <div class="value">上海市合庆镇东胜路229号</div>
        </div>
        <div class="contact-item">
          <div class="title">货代</div>
          <div class="value">
            Nicole Fan
            <span>/</span> 137-0136-3596
            <span>/</span> 021-8037 9180
          </div>
          <div class="email">yrfan@gfscold.com</div>
          <div class="value">上海市浦东新区张杨路2389弄3号普洛斯大厦</div>
        </div>
      </div>
      <div class="contact-row">
        <div class="contact-item">
          <div class="title">库购</div>
          <div class="label">业务合作</div>
          <div class="value">
            Xin Zhang
            <span>/</span> 400-805-1808
            <span>/</span>
            <span class="email">xzhang3@gfscold.com</span>
          </div>
          <div>上海市浦东新区张杨路2389弄3号普洛斯大厦</div>
          <div class="label">专属客服</div>
          <div class="value">
            400-805-1808
            <span>/</span>
            <span class="email">Buy@gfscold.com</span>
          </div>
          <div>上海市浦东新区张杨路2389弄3号普洛斯大厦</div>
        </div>
        <div class="contact-item">
          <div class="title">钱仓</div>
          <div class="value">
            Shukui Ni
            <span>/</span> 021-80379168
            <span>/</span> 189-3996-7769
          </div>
          <div class="email">skni@gfscold.com</div>
          <div class="value">上海市浦东新区张杨路2389弄3号普洛斯大厦</div>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  activated() {
    window.scrollTo(0, 0);
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.contact-container {
  width: 734px;
  left: 0;
  right: 0;
  margin: 38px auto 68px auto;
  margin-bottom: 68px;
  .contact-first-row {
    .title {
      height: 32px;
      font-size: 23px;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 27px;
      color: #1a1a1a;
      opacity: 1;
      margin-bottom: 10px;
    }
    .value {
      // width: 191px;
      height: 21px;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 17px;
      color: #333333;
      opacity: 1;
    }
    .email {
      color: #007c00;
    }
  }
  .contact-row {
    margin-top: 60px;
    .contact-item {
      display: inline-block;
      width: 300px;
      margin-right: 64px;
      // height: 47px;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 17px;
      color: #4d4d4d;
      vertical-align: top;
      opacity: 1;
      .title {
        width: 34px;
        height: 24px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 20px;
        color: #1a1a1a;
        opacity: 1;
        margin-bottom: 10px;
      }
      .value {
        height: 21px;
      }
      .email {
        color: #007c00;
        height: 21px;
      }
      .label {
        font-family: PingFangSC-Medium;
        color: #333333;
        font-size: 11px;
        height: 21px;
        line-height: 21px;
        font-weight: bold;
      }
    }
  }
}
.bottom {
  height: 68px;
}
</style>
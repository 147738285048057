<template>
  <div>
    <div class="page_body_con moment-row">
      <div class="title">辐射宁沪杭｜智本花桥仓7月启用</div>
      <div class="description">辐射华东地区的绝佳冷链配送中心——昆山花桥库，它来了！</div>
      <div class="date">2021-01-27</div>
      <div class="more" @click="$router.push('/moment/momentDetail')">
        更多
        <span class="el-icon-arrow-right"></span>
      </div>
      <img :src="require('@/assets/images/moment/1.png')" width="100%" />
    </div>
    <div class="page_body_con moment-row">
      <div class="title">圣诞新年季 让美食传递爱</div>
      <div class="description">智本库购圣诞礼包，让美食传递爱</div>
      <div class="date">2020-12-27</div>
      <div class="more" @click="$router.push('/moment/momentDetail')">
        更多
        <span class="el-icon-arrow-right"></span>
      </div>
      <img :src="require('@/assets/images/moment/2.png')" width="100%" />
    </div>
    <div class="page_body_con moment-row">
      <div class="title">南京智本助力7-ELEVEn便利店布局江苏</div>
      <div class="description">
        2021跨年夜，南京智本写新篇，智本南京公司7-ELEVEn便利店仓配一体项目正式上线！
      </div>
      <div class="date">2021-01-06</div>
      <div class="more" @click="$router.push('/moment/momentDetail')">
        更多
        <span class="el-icon-arrow-right"></span>
      </div>
      <img :src="require('@/assets/images/moment/3.png')" width="100%" />
    </div>
    <div class="page_body_con moment-row">
      <div class="title">网红 “小黄人” 入驻智本仓</div>
      <div class="description">AGV界的网红“小黄人”入驻智本仓！</div>
      <div class="date">2020-12-18</div>
      <div class="more" @click="$router.push('/moment/momentDetail')">
        更多
        <span class="el-icon-arrow-right"></span>
      </div>
      <img :src="require('@/assets/images/moment/4.png')" width="100%" />
    </div>
    <div class="page_body_con pagination ta-c">
      <el-pagination
        layout="prev, pager, next"
        :total="10"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.moment-row {
  text-align: center;
  padding-top: 44px;
  padding-bottom: 72px;
  // overflow: hidden;
  // float: right;
  .title {
    // width: 268px;
    height: 32px;
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    opacity: 1;
    margin-bottom: 17px;
  }
  .description {
    // width: 153px;
    height: 30px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #333333;
    opacity: 1;
    line-height: 30px;
  }
  .date {
    // width: 153px;
    height: 30px;
    font-size: 10px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #333333;
    opacity: 1;
    line-height: 30px;
  }
  .more {
    // width: 23px;
    height: 16px;
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 19px;
    color: #4d4d4d;
    opacity: 1;
    margin: 31px 0;
    cursor: pointer;
  }
}
.pagination {
  margin-bottom: 55px;
}
</style>

<template>
  <div class="page">
    <div class="page_header_img" style="font-size:0">
      <img :src="require('@/assets/images/header/bg_location.png')" width="100%" />
    </div>
    <div class="search">
      <div class="search-item">
        <span class="label">
          区域：
          <select class="select"></select>
        </span>
      </div>
      <div class="search-item">
        <span class="label">
          城市：
          <select class="select"></select>
        </span>
      </div>
      <div class="search-item">
        <span class="label">
          仓库：
          <select class="select-large"></select>
        </span>
      </div>
      <div class="search-item">
        <button @click="$router.push('/location/locationList')">搜索</button>
      </div>
      <div class="search-item">
        <button>重置</button>
      </div>
    </div>
    <div class="location-row">
      <div class="location-item" @click="$router.push('/location/locationDetail')">
        <div class="image image_1"></div>
        <div class="desc">
          <div class="title">上海浦东和泽园库</div>
          <div>Ke Liu</div>
          <div>181 9247 2667</div>
          <div class="email">kliu@gfscold.com</div>
        </div>
      </div>
      <div class="location-item" @click="$router.push('/location/locationDetail')">
        <div class="image image_2"></div>
        <div class="desc">
          <div class="title">天津西青辛口库</div>
          <div>David Chen</div>
          <div>186 0221 5066</div>
          <div class="email">wchen1@gfscold.com</div>
        </div>
      </div>
      <div class="location-item" @click="$router.push('/location/locationDetail')">
        <div class="image image_3"></div>
        <div class="desc">
          <div class="title">成都青白江库</div>
          <div>Mavis Ma</div>
          <div>181 2123 8096</div>
          <div class="email">rhma@gfscold.com</div>
        </div>
      </div>
      <div class="location-item" @click="$router.push('/location/locationDetail')">
        <div class="image image_4"></div>
        <div class="desc">
          <div class="title">大连智本獐子岛库</div>
          <div>Tracy Cui</div>
          <div>158 9817 3020</div>
          <div class="email">ytcui1@gfscold.com</div>
        </div>
      </div>
      <div class="location-item" @click="$router.push('/location/locationDetail')">
        <div class="image image_5"></div>
        <div class="desc">
          <div class="title">上海合庆自动化电商库</div>
          <div>Evan Lian</div>
          <div>150 0013 2960</div>
          <div class="email">lblian@gfscold.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      value1: '',
      options: []
    }
  },

  activated() {
    window.scrollTo(0, 0);
  },
}
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  background-color: #e6e9ed;
  height: 46px;
  line-height: 46px;
  .search-item {
    display: inline-block;
    margin-left: 43px;
    cursor: pointer;
    .label {
      font-weight: bold;
    }
  }
}

.select {
  width: 50px;
  height: 17px;
  background: #ffffff;
  opacity: 1;
  border-radius: 2px;
  border: none;
}
.select-large {
  width: 103px;
  height: 17px;
  background: #ffffff;
  opacity: 1;
  border-radius: 2px;
  border: none;
}
button {
  width: 50px;
  height: 17px;
  background: #ffffff;
  opacity: 1;
  border-radius: 2px;
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 300;
  color: #808080;
  opacity: 1;
  border: 0;
}
.location-row {
  margin-top: 15px;
  text-align: center;
  width: 100vw;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-bottom: 88px;
}
.location-item {
  width: 204px;
  height: 180px;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  cursor: pointer;
  .image {
    width: 100%;
    height: 112px;
    background-size: 100% 180px;
    background-repeat: no-repeat;
  }
  .image_1 {
    background-image: url("~@assets/images/location/1.png");
  }
  .image_2 {
    background-image: url("~@assets/images/location/2.png");
  }
  .image_3 {
    background-image: url("~@assets/images/location/3.png");
  }
  .image_4 {
    background-image: url("~@assets/images/location/4.png");
  }
  .image_5 {
    background-image: url("~@assets/images/location/5.png");
  }
  .image_6 {
    background-image: url("~@assets/images/location/6.png");
  }
  .desc {
    height: 68px;
    width: 100%;
    background-color: #e6e9ed;
    font-size: 8px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #333333;
    opacity: 1;
    text-align: center;
    .title {
      height: 13px;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      opacity: 1;
    }
    .email {
      color: #006ACD;
    }
  }
}
</style>
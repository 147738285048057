<template>
  <div class="solution_page">
    <div class="">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  activated() {
    window.scrollTo(0, 0);
  },
}
</script>

<style lang="scss" scoped>


</style>